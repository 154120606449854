const numbers = require('./numbers');

module.exports = {
    hideLibbyIntercept: {
        name: 'hideLibbyIntercept',
        durationMS: numbers.dayInMilliSeconds * 56,
        sliding: true,
    },
    hideLibbyInterceptLong: {
        name: 'hideLibbyInterceptLong',
        durationMS: numbers.yearInMilliseconds,
        sliding: true,
    },
    hideLoansPageLibbyPromo: {
        name: 'hideLoansPageLibbyPromo',
        durationMS: numbers.dayInMilliSeconds * 14,
        sliding: true,
    },
    odTrack: {
        name: 'od_track',
        durationMS: numbers.yearInMilliseconds * 10,
        sliding: true,
        domain: 'overdrive.com',
        httpOnly: false,
    },
};
