const { text, url, routes } = window;
import { toStaticUrl } from '../../../../lib/assetMapper';

export default (destination, canUseSVG) => `
<div class="top-nav-bar-Libby-Promo">
    <div class="nav-bar-content">
        <div class="logoBox vertically-centered ">
            <a class="librarySiteLink" aria-label="${html('libraryTitle', {
                libName: window.OverDrive.libraryName,
            })}}" href="${url(routes.home)}">
                <img class="logo" tabindex="-1" aria-hidden="true" alt="${html(
                    'nav.logo',
                    { libraryName: window.OverDrive.libraryName }
                )}" src="${window.OverDrive.siteLogoUrl}"/>
            </a>
        </div>

        <div class="continueText vertically-centered">
            <a class="link" href="${destination}"> ${text(
    'libbyIntercept.continueToLibrary'
)} <i class="icon-forward-arrow"></i></a>
        </div>
    </div>
</div>

<div class="promoBar">

    <div class="row">

        <div class="large-8 columns show-for-large-up">
            <div class="devicesImageContainer">
                <img src="${toStaticUrl(
                    'img/meet-libby-interstitial-devices.png'
                )}" class="devices">
            </div>
        </div>

        <div class="large-3 end columns show-for-large-up textContainer">
            <div class="row meetLibbyBar">

                <div class="libbyImage">
                    <img src='${toStaticUrl(
                        canUseSVG
                            ? 'img/libby-mascot-animated-2.svg'
                            : 'img/libby-mascot-animated.gif'
                    )}' align="middle">
                </div>

                <div class="meetLibbyText">
                    <p>${text('libbyIntercept.meetLibby')}</p>
                </div>

            </div>

            <div class="row textBar">
                <p>${text('libbyIntercept.libbyMakesItEasier')}</p>
                <p>${text('libbyIntercept.justAFewTaps')}</p>
            </div>

            <div class="row buttonsBar">
                <div class="row">
                    <p class="getTheApp">${text(
                        'libbyIntercept.getItToday'
                    )}</p>
                </div>

                <div class="row imageAlign">
                    <a data-ga-action="libby_promo_app" aria-label="${text(
                        'appleAppStore'
                    )}" target="_blank" rel="noreferrer" class="promoLink" href="https://itunes.apple.com/us/app/libby-by-overdrive/id1076402606?mt=8&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_applestore_intercept_page_06012018"> <img class="badge padding" src="${toStaticUrl(
    'img/app-store-badge-apple.svg'
)}"> </a>
                </div>

                <div class="row imageAlign">
                    <a data-ga-action="libby_promo_app" aria-label="${text(
                        'googlePlayStore'
                    )}" target="_blank" rel="noreferrer" class="promoLink" href="https://play.google.com/store/apps/details?id=com.overdrive.mobile.android.libby&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_googleplay_intercept_page_06012018"> <img class="badge padding" src="${toStaticUrl(
    'img/app-store-badge-google.svg'
)}"> </a>
                </div>

                ${
                    !window.featureManager.isEnabled(
                        'removeWindowsFromLibbyTout'
                    )
                        ? `<div class="row imageAlign">
                    <a data-ga-action="libby_promo_app" aria-label="${text(
                        'windowsStore'
                    )}" target="_blank" rel="noreferrer" class="promoLink" href="https://www.microsoft.com/en-us/p/libby-by-overdrive/9p6g3tcr4hzr?&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_microsoft_intercept_page_06012018"> <img class="badge" src="${toStaticUrl(
                              'img/app-store-badge-windows.svg'
                          )}"> </a>
                    <p class="centered-text smallText">(Windows 10+)</p>
                </div>`
                        : ``
                }
            </div>

            <div class="row continueBar">
                <p class="text"> ${text('libbyIntercept.orContinueToLibrary', {
                    continueLink: `<a  href="${destination}" class="continueButton"> ${text(
                        'libbyIntercept.continueToLibraryLower'
                    )} <i class="icon-forward-arrow"></i> </a>`,
                })} </p>
            </div>
        </div>

        <div class="large-3 columns hide-for-large-up textContainer">
            <div class="row meetLibbyBar">

                <div class="libbyImage">
                    <img src='${toStaticUrl(
                        canUseSVG
                            ? 'img/libby-mascot-animated-2.svg'
                            : 'img/libby-mascot-animated.gif'
                    )}' align="middle">
                </div>

                <div class="meetLibbyText">
                    <p>${text('libbyIntercept.meetLibby')}</p>
                </div>

            </div>

            <div class="row textBar">
                <p>${text('libbyIntercept.libbyMakesItEasier')}</p>
                <p>${text('libbyIntercept.justAFewTaps')}</p>
            </div>

            <div class="row buttonsBar">
                <div class="row">
                    <p class="getTheApp">${text(
                        'libbyIntercept.getItToday'
                    )}</p>
                </div>

                <div class="row">
                    <div class="small-12 end imageAlign">
                        <a target="_blank" rel="noreferrer" data-ga-action="libby_promo_app" aria-label="${text(
                            'appleAppStore'
                        )}" class="promoLink" href="https://itunes.apple.com/us/app/libby-by-overdrive/id1076402606?mt=8&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_applestore_intercept_page_06012018"> <img class="badge padding" src="${toStaticUrl(
    'img/app-store-badge-apple.svg'
)}"> </a>
                        <a target="_blank" rel="noreferrer" data-ga-action="libby_promo_app" aria-label="${text(
                            'googlePlayStore'
                        )}" class="promoLink" href="https://play.google.com/store/apps/details?id=com.overdrive.mobile.android.libby&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_googleplay_intercept_page_06012018"> <img class="badge padding" src="${toStaticUrl(
    'img/app-store-badge-google.svg'
)}"> </a>
                    </div>
                </div>

                ${
                    !window.featureManager.isEnabled(
                        'removeWindowsFromLibbyTout'
                    )
                        ? `<div class="row imageAlign">
                    <a target="_blank" rel="noreferrer" data-ga-action="libby_promo_app" aria-label="${text(
                        'windowsStore'
                    )}" class="promoLink" href="https://www.microsoft.com/en-us/p/libby-by-overdrive/9p6g3tcr4hzr?&utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_microsoft_intercept_page_06012018"> <img class="badge" src="${toStaticUrl(
                              'img/app-store-badge-windows.svg'
                          )}"> </a>
                    <p class="centered-text smallText">(Windows 10+)</p>
                </div>`
                        : ``
                }
            </div>

            <div class="row continueBar">
                <p class="text"> ${text('libbyIntercept.orContinueToLibrary', {
                    continueLink: `<a  href="${destination}" class="continueButton"> ${text(
                        'libbyIntercept.continueToLibraryLower'
                    )} <i class="icon-forward-arrow"></i> </a>`,
                })} </p>
            </div>
        </div>

        <div class="large-8 columns end hide-for-large-up">
            <div class="devicesImageContainer">
                <img src="${toStaticUrl(
                    'img/meet-libby-interstitial-devices.png'
                )}" class="devices">
            </div>
        </div>
    </div>

    <div class="bottomBar">
        <div class="row centered-text">
            <p><a data-ga-action="libby_promo" target="_blank" rel="noreferrer" href="https://meet.libbyapp.com/?utm_origin=lightning&utm_page_genre=intercept_page&utm_list=meet_libby&utm_content=libby_intercept_page_06012018" class="libbyColor promoLink"> ${text(
                'libbyIntercept.learnMore'
            )} </a> </p>
            <p>${text('libbyIntercept.broughtToYouBy', {
                libraryName: window.OverDrive.libraryName,
                lineBreak: '<br>',
            })}</p>
        </div>
    </div>

</div>
`;
